.App {
  font-family: 'Space Mono', monospace;
  font-weight: 500;
  font-style: normal;
}

body{
  background-color: #282C34 !important;
}

.App-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  color: white;
  padding: 20px;
}

.App-header {
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: #282c34;
  color: white;
}

.full-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f0f0f0;
  height: fit-content;
}

#about {
  background-color: #fafafa;
}

#contact {
  background-color: #fafafa;
  height: 100vh !important;
}

/* Projects Section Layout */
#projects {
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100vh;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.7s ease-out, transform 0.3s ease-out;
}

#projects.show {
  opacity: 1;
  transform: translateY(0);
}

#projects h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.projects-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%; 
  height:auto;
  max-width: 1200px;
}
.project {
  margin: 20px;
  position: relative;
  width: 200px; 
}

.project-img {
  display: block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 2%;
  transition: transform 0.3s ease-in-out;
  width: 190px;
  height: 150px;
}

.project-img:hover {
  transform: scale(1.05) rotate(-5deg);
}

.project p {
  margin: 20px 0px 0px 0px;
}

.read-more-toggle, .modal-close {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  display: inline-block;
  /* margin-top: 10px; */
}

.read-more-toggle {
  cursor: pointer;
  color: darkgrey;
  text-decoration: none;
  display: inline-block;
  font-size: 2rem; 
  transition: color 0.3s ease, transform 0.3s ease;
}

.read-more-toggle:hover {
  color: black;
  transform: translateX(6px);
}

/* TODO: Responsive modal */

.modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 80vw;
  max-width: 1200px;
  height: auto; 
  max-height: 80vh;
  overflow-y: auto;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.modal.show {
  display: block;
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.modal-open .navbar {
  display: none; /* Hide the navbar */
}


.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal-overlay.show {
  display: block;
  opacity: 1;
}


.modal-close {
  position: fixed;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  transition: transform 0.3s ease-in-out;
}


.modal-close:before,
.modal-close:after {
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  width: 2px;
  height: 30px;
  background-color: black;
  transition: transform 0.3s ease-in-out;
}

.modal-close:before {
  transform: rotate(45deg);
}

.modal-close:after {
  transform: rotate(-45deg);
}

.modal-close:hover:before,
.modal-close:hover:after {
  transform: rotate(90deg);
}

.modal-content {
  display: flex;
  flex-direction: column; 
  height: auto;
}


.github-link {
  margin-top: 20px;
  display: block;
  align-self: flex-start; 
}

.github-link img {
  width: 35px;
  height: 35px;
}

.carousel {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.carousel img {
  max-width: 100%;
  max-height: 100%;
  border: 2px solid #000;
  border-radius: 5px;
}

.text-area {
  flex: 1;
  padding-left: 20px;
  text-align: left;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

/* About Section Layout */
#about {
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

#about.show {
  opacity: 1;
  transform: translateY(0);
}

#about h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

#about .about-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;

}

#about .column-one {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

#about .image-wrapper {
  width: 75%;
  height: 440px;
  /* background-image: url('../public/Images/bitmoji/sticker-fit.png'); */
  background-image: url('../public/Images/headshot.JPEG');
  background-size: 100%;
  border-radius: 10%;
  background-position: 0% 25%;
  background-repeat: no-repeat;
  user-select:none;
}

#about .intro-text-wrapper {
  width: 75%;
  text-align: center;
}

#about .intro-text {
  color: #353935 !important;
  font-size: 1.2rem;
  line-height: 1.5;
  margin-top: 20px;
}

#about .column-two {
  width: 50%;
  padding-left: 20px;
}

#about .tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
  max-width: 408px;
}

#about .tech-stack img{
  width: 45px;
  /* -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */ /** maybe? **/
}

#about .tech-stack img:hover {
  transform: scale(1.2);
}

#about h3 {
  margin-top: 40px;
  font-size: 1.8rem;
}

.experience-item {
  margin-bottom: 20px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: grey;
  margin: 10px 0;
}


#about .experience h4 {
  margin: 15px 0px 0px 0px;
  font-size: 1.4rem;
}

#about .experience p {
  margin: 5px 0;
  font-size: 1rem;
}

/* Contact page layout */
#contact-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#contact-container p{
  text-align: center;
  font-size: 1.2rem;
}
#contact-container a{
  text-decoration: none;
}

.socials-row ul{
  display:flex;
  flex-direction: row;
  width: 100%;
  padding: 0%;
  align-items: center;
  flex-wrap: wrap;
}
.socials-row li{
  display: flex;
  padding: 20px;
  list-style: none;
  flex-direction: column;
  align-items: center;
}

.socials-row img{
  width: 40px;
  padding: 2px;
}

.socials-row a{
  text-decoration: none;
}

.back-to-top-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.back-to-top-link {
  font-family: 'Space Mono', monospace;
  background: none;
  border: none;
  text-decoration: none;
  font-size: 1.2rem;
  color: black;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.back-to-top-link .line {
  content: "";
  display: inline-block;
  width: 50px;
  height: 1px;
  background-color: black;
}

.back-to-top-link:hover {
  color: grey;
}

.back-to-top-link:hover .line {
  background-color: grey;
}

.Scramble-container{
  display: flex;
  flex-direction: column;
  align-items: end;
}

.scrambled-text-wrapper {
  display: flex;
  align-items: center;
}

#scramble-line-2 h1{
  font-size: 2rem;
}

#scramble-line-2{
  padding: 0rem clamp(1rem, 2vw, 2rem);
}


.terminal-cursor {
  font-family: 'Space Mono', monospace;
  font-size: clamp(2rem, 6vw, 6rem);
  color: white;
  margin-right: 10px; /* Space between cursor and text */
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.scrambled-text {
  font-family: 'Space Mono', monospace;
  font-size: clamp(2rem, 6vw, 6rem);
  color: white;
  padding: 0rem clamp(1rem, 2vw, 2rem);
  border-radius: clamp(0.4rem, 0.75vw, 1rem);
  opacity: 0;
  transition: opacity 1s ease-in;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  flex-shrink: 0;
}

.scrambled-text.fade-in {
  opacity: 1;
}

.hover-enabled:hover {
  background-color: #24272E;
  transform: translateY(-2px);
  box-shadow: 
    inset 0 0 10px rgba(0, 0, 0, 0.5), 
    0 4px 8px rgba(0, 0, 0, 0.4);
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
}


.decoded-text {
  font-family: 'Space Mono', monospace;
  /* font-size: clamp(2rem, 6vw, 6rem); */
  color: white;
  cursor: pointer;
  transition: color 0.3s ease;
  text-align: center;
}

.decoded-text:hover {
  color: #00ff00; 
}

/* Bounce animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-3px);
  }
}

.decoded-text {
  cursor: pointer;
  display: inline-block;
  transition: color 0.3s ease;
}

.bounce {
  animation: bounce 2s infinite;
}


/* Default styles for all devices */

/* Small devices (phones, 576px and down) */
@media screen and (max-width: 575.98px) {
  .full-screen {
    height: fit-content !important;
  }

  .App-header{
    display: flex;
  }

  .Scramble-container{
    padding-bottom: 10pc
  }

  #about .about-content{
    justify-content: center;
    flex-wrap: wrap;
  }
  #about .column-one, #about .column-two {
    width: 100%; 
    padding-left:0px;
  }

  #about .image-wrapper {
    width: 100%;
    height: 275px;
    /* background-image: url('../public/Images/bitmoji/sticker-fit.png'); */
    background-image: url('../public/Images/headshot.JPEG');
    background-size: 100%;
    border-radius: 50%;
    background-position: 0% 25%;
    background-repeat: no-repeat;
    user-select:none;
  }

  /* #about .image-wrapper:hover {
    background-image: url('./Images/bitmoji/grin-smile-fit.png');
    background-size: 55%; 
    user-select:none;
  } */
  #about .intro-text-wrapper {
    width: 90%;
    text-align: center;
  }

  #about .experience h4{
    font-size: 1.2rem;
  }
  #about .experience p{
    font-size: 1rem;
  }

  #about .tech-stack img{
    user-select:none;
  }

  #projects .projects-container h2{
    padding-top:50px;
  }

  .modal {
    height: 100%;
    max-height: 75vh;
    top:45%;
  }

  .carousel img{
    width: 95%;
  }


  #scramble-line-2{
    padding:0px;
  }
  #scramble-line-2 h1{
    font-size: 1.3rem;
  }
  /* #scrambled-text{
    padding: 0px;
  } */
  
  
}

/* Medium devices (tablets, 768px and up) */
@media screen and (min-width: 768px) {
 
  
}

/* Large devices (desktops, 992px and up) */
@media screen and (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media screen and (min-width: 1200px) {

}
